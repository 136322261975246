import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const mapView = () => import('../views/mapView.vue');
const landingPage = () => import('../views/landingPage.vue');
const Login = () => import('../views/loginPage.vue');
const ForgotPage = () => import('../views/forgotPage.vue');
const ResetPage = () => import('../views/resetPage.vue');
const profile = () => import('../views/profile/profilePage.vue');
const UserRegistration = () => import('../views/userRegistration.vue');
const constructionControl = () => import('../views/constructionControl.vue');

// Dashboard Views START
const AnalyticsDashboardHub = () =>
  import('../views/dashboard-views/AnalyticsDashboardHub.vue');
const CoopAgreementPage = () =>
  import('../views/dashboard-views/CoopAgreementPage.vue');
const MevPage = () => import('../views/dashboard-views/MevPage.vue');
const GrantsPage = () => import('../views/dashboard-views/GrantsPage.vue');
const TenderNetworkOpsPage = () =>
  import('../views/dashboard-views/TenderNetworkOpsPage.vue');
const TenderGeneralCoPage = () =>
  import('../views/dashboard-views/TenderGeneralCoPage.vue');
const PlanningGeneralCoPage = () =>
  import('../views/dashboard-views/PlanningGeneralCoPage.vue');
const DocumentsPage = () => import('../features/documents/documentsPage.vue');

// Dashboard Views END

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        layout: 'auth',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/forgot-password',
      name: 'ForgotPage',
      component: ForgotPage,
      meta: {
        layout: 'auth',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/reset_password',
      name: 'ResetPage',
      component: ResetPage,
      meta: {
        layout: 'auth',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/',
      name: 'landingURL',
      component: landingPage,
      meta: {
        requiresAuth: true,
        title: 'Startseite',
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/construction-control',
      name: 'constructionControl',
      component: constructionControl,
      meta: {
        requiresAuth: true,
        title: 'Digitale Bausteuerung',
        hide_breadcrumb: 0,
        hide_padding: 0,
      },
    },
    {
      path: '/dashboard',
      name: 'analyticsDashboardHubParent',
      meta: {
        layout: 'dashboard',
        requiresAuth: true,
        title: 'Dashboard',
        hide_breadcrumb: 0,
        hide_padding: 0,
      },
      children: [
        {
          path: '', // This is an empty path for the default child
          name: 'analyticsDashboardHub',
          component: AnalyticsDashboardHub,
          meta: {
            id: 0,
          },
        },
        {
          path: 'kooperationsvereinbarung',
          name: 'CoopAgreementPage',
          component: CoopAgreementPage,
          meta: {
            title: 'Kooperations-vereinbarung',
            id: 1,
            disabled: true,
          },
        },
        {
          path: 'markterkundungsverfahren',
          name: 'MevPage',
          component: MevPage,
          meta: {
            title: 'Markterkundungs-verfahren',
            actionBeforeLoad: 'ticket/GET_MEV_DASHBOARD_DATA',
            id: 2,
          },
        },
        {
          path: 'foerderantrag',
          name: 'GrantsPage',
          component: GrantsPage,
          meta: {
            title: 'Förderantrag',
            id: 3,
          },
        },
        {
          path: 'ausschreibung-netzbetrieb',
          name: 'TenderNetworkOpsPage',
          component: TenderNetworkOpsPage,
          meta: {
            title: 'Ausschreibung Netzbetrieb',
            id: 4,
          },
        },
        {
          path: 'ausschreibung-generaluebernehmer',
          name: 'TenderGeneralCoPage',
          component: TenderGeneralCoPage,
          meta: {
            title: 'Ausschreibung\nGÜ',
            id: 5,
            disabled: true,
          },
        },
        {
          path: 'planung-bau-generaluebernehmer',
          name: 'PlanningGeneralCoPage',
          component: PlanningGeneralCoPage,
          meta: {
            title: 'Planung und Bau GÜ',
            actionBeforeLoad: 'dashboard/GET_PLANNING_CONSTRUCTION_DATA',
            id: 6,
          },
        },
      ],
    },
    {
      path: '/mapview/:ags?/:mev_rl?',
      name: 'mapViewAgsCenter',
      component: mapView,
      meta: {
        requiresAuth: true,
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/mapview/:layerName/:lat/:lng',
      name: 'mapView',
      component: mapView,
      meta: {
        requiresAuth: true,
        hide_breadcrumb: 1,
        hide_padding: 1,
      },
    },
    {
      path: '/registration/:token',
      name: 'dataMask',
      component: UserRegistration,
      meta: {
        hide_breadcrumb: 1,
        layout: 'auth',
        hide_padding: 1,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: profile,
      meta: {
        requiresAuth: true,
        title: 'Mein Profil',
      },
    },
    {
      path: '/documents',
      name: 'DocumentsPage',
      component: DocumentsPage,
      meta: {
        requiresAuth: true,
        title: 'Dokumente',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: () => import('@/views/notFoundPage.vue'),
      meta: {
        hide_breadcrumb: 1,
        layout: 'error',
        hide_padding: 1,
      },
    },
  ],
});
/**
 * Before each route updated
 */
router.beforeEach(async (to) => {
  if (to.meta.requiresAuth) {
    await store.dispatch('auth/GET_PROFILE_DATA').catch(() => {
      const query = !window.location.pathname.includes('reset_password')
        ? { from: window.location.pathname }
        : null;
      router.push({ name: 'Login', ...(query && { query }) });
    });
  }
});

export default router;
