import { cloneDeep } from 'lodash';
import { RL_2022, RL_2023, RL_2024, RL_2024_1 } from '@/configs/sidebar/mev';

function formatNumber(item, value) {
  if (typeof value === 'undefined' || value === null) return '-';
  if (typeof value === 'string' || value instanceof String || item.forceString)
    return `${value}`;
  return (item.multiply ? value * item.multiply : value).toLocaleString(
    'de-DE',
    {
      maximumFractionDigits: item.decimal,
      minimumFractionDigits: item.decimal,
    }
  );
}

function formatProperties(state) {
  const feature = state.mainResult;
  const featureConfig = state.layerConfigs[state.layerID].sidebar;

  // add subtitles
  if (featureConfig.subtitles) {
    featureConfig.subtitles.forEach((subtitle, index1) => {
      const key = subtitle.key;
      const option = featureConfig.OPTIONS[key];
      let value = feature[key];
      if (option.type === 'choice') {
        value = option.choices.find(
          (e) => e.value === feature[key]
        ).display_name;
      }
      featureConfig.subtitles[index1] = {
        key,
        value,
        ...option,
      };
    });
  }

  if (featureConfig.longSubtitles) {
    featureConfig.longSubtitles.forEach((longSubtitle, index1) => {
      const key = longSubtitle.key;
      const option = featureConfig.OPTIONS[key];
      let value = feature[key];
      if (option.type === 'choice') {
        value = option.choices.find(
          (e) => e.value === feature[key]
        ).display_name;
      }
      featureConfig.longSubtitles[index1] = {
        key,
        value,
        ...option,
      };
    });
  }

  if (featureConfig.statusHeader) {
    featureConfig.statusHeader.items.forEach((statusHeader, index1) => {
      const key = statusHeader.key;
      const option = featureConfig.OPTIONS[key];
      let value = feature[key];
      featureConfig.statusHeader.items[index1].value = value;
      featureConfig.statusHeader.items[index1] = {
        ...featureConfig.statusHeader.items[index1],
        ...option,
      };
    });
  }

  if (featureConfig.buttons) {
    featureConfig.buttons.forEach((button, index1) => {
      if ('show' in button) {
        const key = button.show.key;
        const option = featureConfig.OPTIONS[key];
        let value = feature[key];
        featureConfig.buttons[index1].show.value = value;
        featureConfig.buttons[index1].show = {
          ...featureConfig.buttons[index1].show,
          ...option,
        };
      }
    });
  }

  // add chips
  featureConfig.chips.forEach((chip, index1) => {
    chip.items.forEach((category, index2) => {
      category.items.forEach((item, index3) => {
        let key = item.key;

        if (typeof feature[key] !== 'undefined') {
          const option = featureConfig.OPTIONS[key];
          const field = featureConfig.chips[index1].items[index2].items[index3];

          let value = feature[key];

          if (['choice', 'list'].includes(option.type)) field.value = value;
          else field.value = formatNumber(item, value);

          // merge options into field object
          featureConfig.chips[index1].items[index2].items[index3] = {
            ...field,
            ...option,
          };
        }
      });
    });
  });

  // add delete button props
  if (featureConfig.buttons) {
    featureConfig.buttons.forEach((button, index) => {
      const key = button.entityId.key;
      const option = featureConfig.OPTIONS[key];
      const value = feature[key];
      featureConfig.buttons[index].entityId = {
        key,
        value,
        ...option,
      };
    });
  }

  // add intro text props
  if (featureConfig.introText) {
    const key = featureConfig.introText.key;
    const option = featureConfig.OPTIONS[key];
    const value = feature[key];
    featureConfig.introText = {
      key,
      value,
      ...option,
    };
  }

  // add comment props
  if (featureConfig.comments) {
    const idKey = featureConfig.comments.entityId.key;
    const idOption = featureConfig.OPTIONS[idKey];
    const idValue = feature[idKey];
    featureConfig.comments.entityId = {
      key: idKey,
      value: idValue,
      ...idOption,
    };
    const commentsKey = featureConfig.comments.comments.key;
    const commentsOption = featureConfig.OPTIONS[commentsKey];
    const commentsValue = feature[commentsKey];
    featureConfig.comments.comments = {
      key: commentsKey,
      value: commentsValue,
      ...commentsOption,
    };
  }

  // add metadata
  if (featureConfig.metaData) {
    featureConfig.metaData.forEach((item, index) => {
      const key = item.key;
      const option = featureConfig.OPTIONS[key];
      const value = feature[key];
      featureConfig.metaData[index] = {
        key,
        value,
        ...option,
      };
    });
  }
  return cloneDeep(featureConfig);
}

export default {
  getLegendEntries: (state, _, __, rootGetters) => {
    const vis = [];
    Object.entries(state.layerConfigs).forEach((item) => {
      const e = item[1];
      if (e.visible && e.legend) {
        const legend = e.legend;
        // const legend = e.legend[e.layoutState];
        if (legend[e.layoutState]) {
          if ('replace' in legend) {
            if (legend.replace.multiple) {
              legend[e.layoutState] = JSON.parse(
                rootGetters[legend.replace.storageGetterForReplacement].reduce(
                  (prev, cur, curI) =>
                    prev.replaceAll(legend.replace.toReplace + curI, cur),
                  JSON.stringify(legend[e.layoutState])
                )
              );
            } else {
              legend[e.layoutState] = JSON.parse(
                JSON.stringify(legend[e.layoutState]),
                rootGetters[legend.replace.storageGetterForReplacement]
              );
            }
          }
          vis.push(legend[e.layoutState]);
        }
      }
    });
    return vis;
  },
  getLayerStates: (state) =>
    Object.keys(state.layerConfigs).map((e) => ({
      layer: e,
      state: state.layerConfigs[e].layoutState,
      visible: state.layerConfigs[e].visible,
    })),
  getSwitchStates: (state) => {
    // simplify config
    const switchStates = {};
    for (const category of state.navigationConfig) {
      for (const section of category.sections) {
        for (const group of section.items) {
          if (group.items) {
            for (const switchItem of group.items) {
              if (switchItem.itemClass === 'switch') {
                switchStates[switchItem.switchId] = switchItem.active;
              }
            }
          }
        }
      }
    }
    return switchStates;
  },
  getLayersToClick: (state) => {
    const layers = [];
    Object.entries(state.layerConfigs).forEach((item) => {
      if (item[1].sidebar || item[1].onClick) {
        layers.push(item[0]);
      }
    });
    return layers;
  },
  getHoverPointer: (state) => {
    const layers = [];
    Object.entries(state.layerConfigs).forEach((item) => {
      const conf = item[1].layerConfig;
      if (conf.hoverPointer) {
        layers.push(conf.id);
      }
    });
    return layers;
  },
  getProperties: (state) => formatProperties(state),
  layerId: (state) => state.layerID,
  getPropertyDataIsLoading: (state) => state.propertyDataIsLoading,
  hasResized: (state) => state.hasResized,
  getModelOptions: (state) => state.layerConfigs[state.layerID].sidebar.OPTIONS,
  getFeatureIsEditable: () => [],
  getContainerHeight: (state) => state.containerHeight,
  getShowDataPanel: (state) => state.showDataPanel,
  getMapActionsDialogIsOpen: (state) => state.mapActionsDialogIsOpen,
  getActiveMevName: (state) => {
    if (state.richtlinie === 2022) {
      return RL_2022;
    } else if (state.richtlinie === 2023) {
      return RL_2023;
    } else if (state.richtlinie === 2024) {
      return RL_2024;
    } else if (state.richtlinie === '2024_1') {
      return RL_2024_1
    }
  },
  getPhaseName: (state) => {
    const numbers = state.phase;
    if (numbers.includes(3) && !numbers.includes(4)) {
      return 'Hellgraue Flecken';
    } else if (numbers.includes(4) && !numbers.includes(3)) {
      return 'Dunkelgraue Flecken';
    } else {
      return 'Alle Flecken';
    }
  },
  getAvailableTenderYears: (state) => state.availableTenderYears,
  getMevRichtlinie: (state) => state.richtlinie,
};
