<template>
  <DropDown
    v-model="selectedSource"
    class="py-2 px-4 bg-infra-highlight-25"
    :items-data="selectedSourceData"
    items-data-key="name"
    size="m"
    :label="menuItem.label"
    :rules="{ required: true }"
    @update:model-value="(val) => {setSource(val); setSource(val)}" />
</template>

<script>
import { changeSourceLayer } from '@/mapbox/main';
import { SWITCH_ID__MEV_RESULTS } from '@/configs/sidebar/mev';

export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    selectedSource() {
      return this.$store.getters['map/getMevRichtlinie']
    },
    selectedSourceData() {
      return this.menuItem.items.map((item) => item.title);
    },

    filteredValueData() {
      return this.menuItem.items
        .map((item) => item.value)
        .filter((item) => item.richtlinie === this.selectedSource)[0];
    },
  },
  methods: {
    setSource(val) {
      changeSourceLayer(this.menuItem.layers, this.filteredValueData.source);
      this.$store.commit('map/SET_MEV_RICHTLINIE', val);
      this.$store.commit('map/SET_SHOW_DATA_PANEL', false);
      let item = this.menuItem.items.find(
        (e) => e.value.richtlinie === val
      );
      if (item.selectLayersOnClick) {
        this.$store.commit('map/CHANGE_SWITCH', {
          switches: item.selectLayersOnClick,
          active: true,
        });
      }
    },
  },
};
</script>
