<template>
  <div class="grid grid-cols-3 gap-2.5">
    <div class="flex flex-col gap-[25px]">
      <drop-down-multiple
        v-model="selectedLandkreise"
        item-type-all="Landkreise"
        :max-height-dropdown="250"
        :ensure-one-selected="false"
        label="Landkreise"
        placeholder="Bitte Landkreise auswählen"
        custom-background="bg-infra-highlight-25"
        :items="getLandkreise" />
      <drop-down-multiple
        v-if="routeIsMev"
        v-model="tkuSelected"
        data-key="name"
        value-key="value"
        :init-select-all="true"
        :select-all-on-items-change="true"
        item-type-all="TKUs"
        :max-height-dropdown="250"
        label="TKU"
        placeholder="Bitte TKU auswählen"
        :option-select-all="true"
        custom-background="bg-infra-highlight-25"
        :items="selectableTku" />
      <drop-down-multiple-grouped
        v-if="routeIsPlanningAndConstruction"
        :items="selectableClusterLots"
        label="Ausgewählte Cluster/Lose"
        placeholder="Cluster/Lose auswählen"
        custom-background="bg-infra-highlight-25"
        data-key="name"
        value-key="value"
        item-type-all="Cluster/Lose"
        @selected-items="clusterLotsSelected" />
    </div>
    <div class="flex flex-col gap-[25px]">
      <drop-down-multiple
        v-model="selectedGemeinden"
        item-type-all="Gemeinden"
        :ensure-one-selected="false"
        :max-height-dropdown="250"
        label="Gemeinden"
        placeholder="Bitte Gemeinden auswählen"
        custom-background="bg-infra-highlight-25"
        :items="getGemeindenFiltered"
        data-key="name"
        value-key="ags" />
      <drop-down-multiple
        v-if="routeIsPlanningAndConstruction"
        v-model="selectedSections"
        :init-select-all="true"
        :select-all-on-items-change="true"
        item-type-all="Bauabschnitte"
        :ensure-one-selected="false"
        :max-height-dropdown="250"
        label="Ausgewählte Bauabschnitte"
        :items="selectableSections"
        data-key="name"
        value-key="value" />
    </div>
    <div class="flex flex-col gap-[25px]">
      <DropDown
        v-if="routeIsMevOrGrants"
        v-model="richtlinie"
        label="Förderrichtlinie"
        :initial-selection="true"
        items-data-key="name"
        custom-background="bg-infra-highlight-25"
        :rules="{ required: true }"
        :items-data="getRichtlinien"
        @update:model-value="
          (val) => {
            richtlinie = getRichtlinien.filter((r) => r.value === val);
          }
        " />
      <drop-down-multiple
        v-else-if="!routeIsPlanningAndConstruction"
        v-model="richtlinie"
        :option-select-all="false"
        :ensure-one-selected="true"
        :max-height-dropdown="250"
        label="Förderrichtlinie"
        custom-background="bg-infra-highlight-25"
        :items="getRichtlinien"
        item-type-all="Richtlinien"
        data-key="name"
        value-key="value" />
      <div v-if="routeIsMev" class="w-30 ml-auto mt-auto">
        <csv-data-download :richtlinie="richtlinie" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DropDownMultipleGrouped from '@/components/storybook/src/stories/dropDownMultipleGrouped/DropDownMultipleGrouped.vue';

export default {
  name: 'DashboardFilter',
  components: { DropDownMultipleGrouped },
  data() {
    return {
      tab: 'mev',
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters('ticket', [
      'getMevDashboardData',
      'getMevGemarkungenData',
      'getRichtlinien',
      'getRichtlinieSelected',
      'getLandkreise',
      'getLandkreiseActive',
      'getGemeindenFiltered',
      'getGemeindenActive',
      'getTku',
      'getTkuActive',
      'getBuilttData',
    ]),
    ...mapGetters('dashboard', [
      'getPlanningConstructionData',
      'getSelectedLots',
      'getSelectedSections',
    ]),

    selectableClusterLots() {
      const gemeindenSet = new Set(this.getGemeindenActive.map((g) => g.ags));
      let result = [
        {
          name: 'Alle',
          value: 'all',
          checked: true,
          items: [],
        },
      ];
      if (this.$route.path === '/construction-control') {
        let preFilteredData = JSON.parse(JSON.stringify(this.getBuilttData));
        for (let lot of preFilteredData) {
          lot.sections = lot.sections.filter((section) => {
            return gemeindenSet.has(section.gemeinde_id);
          });
        }
        preFilteredData = preFilteredData.filter(
          (lot) => lot.sections.length > 0
        );
        if (preFilteredData.length === 0) return [];
        let byCluster = Object.groupBy(
          preFilteredData,
          (item) => item.cluster_id
        );
        for (const entries of Object.values(byCluster)) {
          if (entries.length === 0) continue;
          const lots = entries.map((item) => {
            return {
              value: item.lot_id,
              name: item.lot_name,
              checked: true,
            };
          });
          const uniqueLots = Object.values(
            lots.reduce((acc, obj) => ({ ...acc, [obj.value]: obj }), {})
          );
          result[0].items.push({
            value: entries[0].cluster_id,
            name: entries[0].cluster_name,
            checked: true,
            items: uniqueLots,
            expanded: false,
          });
        }
      } else {
        const preFilteredData = this.getPlanningConstructionData.filter(
          (item) => {
            return gemeindenSet.has(item.gemeinde_id);
          }
        );
        if (preFilteredData.length === 0) return [];
        let byCluster = Object.groupBy(
          preFilteredData,
          (item) => item.cluster_id
        );
        for (const entries of Object.values(byCluster)) {
          if (entries.length === 0) continue;
          const lots = entries.map((item) => {
            return {
              value: item.lot_id,
              name: item.lot_name,
              checked: true,
            };
          });
          const uniqueLots = Object.values(
            lots.reduce((acc, obj) => ({ ...acc, [obj.value]: obj }), {})
          );
          result[0].items.push({
            value: entries[0].cluster_id,
            name: entries[0].cluster_name,
            checked: true,
            items: uniqueLots,
            expanded: false,
          });
        }
      }
      return result;
    },

    selectableSections() {
      const lotSet = new Set(this.getSelectedLots);
      if (this.$route.path === '/construction-control') {
        return this.getBuilttData
          .filter((lot) => lotSet.has(lot.lot_id))
          .reduce((acc, cur) => {
            return [
              ...acc,
              ...cur.sections.map((section) => {
                return {
                  value: section.section_id,
                  name: section.section_name,
                };
              }),
            ];
          }, []);
      } else {
        return this.getPlanningConstructionData
          .filter((item) => lotSet.has(item.lot_id))
          .map((section) => {
            return {
              value: section.section_id,
              name: section.section_name,
            };
          });
      }
    },

    selectableTku() {
      return this.getTku.map((e) => ({
        name: e === '-' || e === '' ? ' Kein eigenwirt. Ausbau' : e,
        value: e,
      }));
    },

    selectedLandkreise: {
      get() {
        return this.getLandkreiseActive;
      },
      set(value) {
        this.$store.commit('ticket/SET_LANDKREISE_ACTIVE', value);
      },
    },

    selectedGemeinden: {
      get() {
        return this.getGemeindenActive;
      },
      set(value) {
        this.$store.commit('ticket/SET_GEMEINDEN_ACTIVE', value);
      },
    },

    selectedSections: {
      get() {
        return this.getSelectedSections;
      },
      set(value) {
        this.$store.commit('dashboard/SET_SELECTED_SECTIONS', value);
      },
    },

    richtlinie: {
      get() {
        if (this.routeIsMevOrGrants) {
          return this.getRichtlinieSelected[0].value;
        } else {
          return this.getRichtlinieSelected;
        }
      },
      set(value) {
        this.$store.commit('ticket/SET_RICHTLINIE', value);
      },
    },

    tkuSelected: {
      get() {
        return this.getTkuActive;
      },
      set(value) {
        this.$store.commit('ticket/SET_TKU_ACTIVE', value);
      },
    },

    routeIsMevOrGrants() {
      return (
        this.$route.path === '/dashboard/markterkundungsverfahren' ||
        this.$route.path === '/dashboard/foerderantrag'
      );
    },

    routeIsPlanningAndConstruction() {
      return (
        this.$route.path === '/dashboard/planung-bau-generaluebernehmer' ||
        this.$route.path === '/construction-control'
      );
    },

    routeIsMev() {
      return this.$route.path === '/dashboard/markterkundungsverfahren';
    },

    tabStyle() {
      return 'ml-0 px-2 normal-case hover:text-infra-highlight-500 tracking-normal';
    },
    categoryOrderData() {
      return this.getMevDashboardData
        ? this.getMevDashboardData.data.fields.mev_class
        : null;
    },

    dataFilteredByGemeinden() {
      if (!this.selectedGemeinden) {
        return [];
      }

      return this.getMevDashboardData.data.values.filter((item) =>
        this.selectedGemeinden.includes(item.gemeinde_id)
      );
    },

    expansionData() {
      return this.aggregateData(
        this.dataFilteredByGemeinden,
        'expansion',
        this.getTku
      );
    },

    expansionCompanyData() {
      const aggregatedData = {};
      this.dataFilteredByGemeinden.forEach((item) => {
        item['expansion'].forEach((subItem) => {
          const key = subItem['expansion'];
          const companyKey = subItem['company'];
          if (aggregatedData[key] !== undefined) {
            if (aggregatedData[key][companyKey] !== undefined) {
              aggregatedData[key][companyKey] += subItem.count;
            } else {
              aggregatedData[key][companyKey] = subItem.count;
            }
          } else {
            aggregatedData[key] = { [companyKey]: subItem.count };
          }
        });
      });
      return Object.entries(aggregatedData).map(([key, value]) => ({
        name: this.getTku[Number(key)],
        value: value,
      }));
    },

    versorgungsGradData() {
      return this.aggregateData(
        this.dataFilteredByGemeinden,
        'versorgungsgrad',
        this.legendDataVersorgungsgrad
      );
    },

    mevData() {
      return this.dataFilteredByGemeinden.map((item) => ({
        mev: [...item.mev],
        gemeinde_name: item.name.gemeinde_name,
        gemeinde_id: item.gemeinde_id,
      }));
    },

    mevResultsGemarkungenFormatted() {
      return this.getMevGemarkungenData?.data.values.map((item) => ({
        mev: [...item.mev],
        gemeinde_name: item.name,
        gemeinde_id: item.gemarkung_id,
      }));
    },

    legendDataVersorgungsgrad() {
      return this.getMevDashboardData.data.fields.versorgungsgrad;
    },
  },

  watch: {
    selectedLandkreise: {
      handler(val) {
        this.$store.commit('ticket/SET_GEMEINDEN_FILTERED', val);
        this.selectedGemeinden = this.getGemeindenFiltered;
      },
    },
    getGemeindenFiltered: {
      handler(val) {
        if (this.getGemeindenActive) return;
        this.selectedGemeinden = val;
      },
      immediate: true,
    },
  },
  methods: {
    aggregateData(inputData, field, legendDataMapping) {
      const aggregatedData = {};

      inputData.forEach((item) => {
        item[field].forEach((subItem) => {
          const key = subItem[field];
          if (aggregatedData[key] !== undefined) {
            aggregatedData[key] += subItem.count;
          } else {
            aggregatedData[key] = subItem.count;
          }
        });
      });

      return Object.entries(aggregatedData).map(([key, value]) => ({
        name: legendDataMapping[Number(key)],
        value: value,
      }));
    },
    clusterLotsSelected(clusterLots) {
      if (clusterLots.length === 0) {
        this.$store.commit('dashboard/SET_SELECTED_LOTS', []);
        return;
      }
      const selectedLotIds = clusterLots[0].items.reduce((acc, cluster) => {
        return [
          ...acc,
          ...cluster.items.filter((lot) => lot.checked).map((lot) => lot.value),
        ];
      }, []);
      this.$store.commit('dashboard/SET_SELECTED_LOTS', selectedLotIds);
    },
  },
};
</script>
