<template>
  <div v-if="!isLoaded">
    <skeleton-loader-list />
  </div>
  <div v-else class="h-full gap-5 flex flex-col pt-5">
    <div class="gap-2 flex">
      <drop-down-multiple
        v-model="filteredData"
        :init-select-all="true"
        class="w-[100%]"
        :max-height-dropdown="250"
        label="Gemeindefilter"
        custom-background="bg-infra-highlight-25"
        :items="uniqueNames"
        data-key="name"
        value-key="gemeinde_id" />
      <DropDown
        v-model="richtlinie"
        class="w-[50%]"
        label="Förderrichtlinie"
        :items-data="[RL_2022(), RL_2023(), RL_2024(), RL_2024_1()]"
        :rules="{ required: true }"
        items-data-key="name" />
    </div>
    <div
      class="flex justify-between items-center w-full border-b-2 border-[#e0e0e0] mb-[20px]">
      <h4 class="text-infra-highlight-700 pb-1">
        Ergebnis
        {{
          gemarkungenIsLoaded && splitGemarkung ? 'Gemarkungen' : 'Gemeinden'
        }}
      </h4>

      <div v-if="filteredData && filteredData.length === 1" class="w-fit">
        <vuetify-switch
          label="Split nach Gemarkung"
          @selected="splitGemarkung = $event" />
      </div>
    </div>
    <div v-if="!filteredData || filteredData.length === 0" class="body-1">
      Keine Gemeinde ausgewählt
    </div>
    <div v-else class="flex flex-col gap-6">
      <div class="w-full">
        <stackedbar-chart
          :custom-min-height="38"
          :bar-width="15"
          :category-order="categoryOrderData"
          x-axis-name="Anzahl der Adressen"
          :chart-data="
            splitGemarkung && gemarkungenIsLoaded && filteredData.length === 1
              ? mevResultsGemarkungenFormatted
              : mevData
          " />
      </div>
      <div class="grid grid-cols-2 gap-6">
        <div class="w-full flex justify-center">
          <doughtnut-chart
            class="max-w-[60%] min-w-[400px]"
            :custom-radius="['42%', '55%']"
            :legend-data="legendDataExpansion"
            :custom-tooltip="expansionCompanyData"
            :show-default-tooltip="false"
            title="Ausbauankündigungen"
            title-inside="Gebäude"
            custom-margin-bottom="-mb-10"
            :chart-data="expansionData" />
        </div>
        <div class="w-full flex justify-center">
          <doughtnut-chart
            class="max-w-[60%] min-w-[400px]"
            :custom-radius="['42%', '55%']"
            :legend-data="legendDataVersorgungsgrad"
            :show-default-tooltip="false"
            title="Versorgungsgrad"
            title-inside="Gebäude"
            custom-margin-bottom="-mb-10"
            :chart-data="versorgungsGradData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RL_2022, RL_2023, RL_2024, RL_2024_1 } from '@/configs/sidebar/mev';

export default {
  name: 'MevWindowItem',
  props: {
    landkreise: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoaded: false,
      filteredData: [],
      splitGemarkung: false,
      gemarkungenIsLoaded: false,
      richtlinie: 2022,
    };
  },
  computed: {
    ...mapGetters('ticket', ['getMevDashboardData', 'getMevGemarkungenData']),
    categoryOrderData() {
      return this.getMevDashboardData
        ? this.getMevDashboardData.data.fields.mev_class
        : null;
    },

    filteredByGemeinden() {
      if (!this.filteredData || this.filteredData.length === 0) {
        return [];
      }

      const gemeindeIdsSet = new Set(this.filteredData.map((item) => item));

      return this.getMevDashboardData.data.values.filter((item) =>
        gemeindeIdsSet.has(item.gemeinde_id)
      );
    },

    expansionData() {
      return this.aggregateData(
        this.filteredByGemeinden,
        'expansion',
        this.legendDataExpansion
      );
    },

    expansionCompanyData() {
      const aggregatedData = {};
      this.filteredByGemeinden.forEach((item) => {
        item['expansion'].forEach((subItem) => {
          const key = subItem['expansion'];
          const companyKey = subItem['company'];
          if (aggregatedData[key] !== undefined) {
            if (aggregatedData[key][companyKey] !== undefined) {
              aggregatedData[key][companyKey] += subItem.count;
            } else {
              aggregatedData[key][companyKey] = subItem.count;
            }
          } else {
            aggregatedData[key] = { [companyKey]: subItem.count };
          }
        });
      });
      return Object.entries(aggregatedData).map(([key, value]) => ({
        name: this.legendDataExpansion[Number(key)],
        value: value,
      }));
    },

    versorgungsGradData() {
      return this.aggregateData(
        this.filteredByGemeinden,
        'versorgungsgrad',
        this.legendDataVersorgungsgrad
      );
    },

    mevData() {
      return this.filteredByGemeinden.map((item) => ({
        mev: [...item.mev],
        gemeinde_name: item.name.gemeinde_name,
        gemeinde_id: item.gemeinde_id,
      }));
    },

    mevResultsGemarkungenFormatted() {
      return this.getMevGemarkungenData?.data.values.map((item) => ({
        mev: [...item.mev],
        gemeinde_name: item.name,
        gemeinde_id: item.gemarkung_id,
      }));
    },

    uniqueNames() {
      const filteredValues = this.getMevDashboardData.data.values.filter(
        (item) => this.landkreise.includes(item.name.landkreis_name)
      );

      const uniqueMapping = {};

      filteredValues.forEach((item) => {
        const gemeindeName = item.name.gemeinde_name;
        if (!uniqueMapping[gemeindeName]) {
          uniqueMapping[gemeindeName] = item.gemeinde_id;
        }
      });

      return Object.entries(uniqueMapping).map(([name, gemeinde_id]) => ({
        name,
        gemeinde_id,
      }));
    },

    legendDataExpansion() {
      return this.getMevDashboardData.data.fields.expansion;
    },

    legendDataVersorgungsgrad() {
      return this.getMevDashboardData.data.fields.versorgungsgrad;
    },
  },
  watch: {
    richtlinie() {
      if (this.splitGemarkung) {
        this.$store
          .dispatch('ticket/GET_MEV_GEMARKUNGEN_DATA', {
            gemeinde: this.filteredData[0].gemeinde_id,
            richtlinie: this.richtlinie,
          })
          .then(() => {
            this.gemarkungenIsLoaded = true;
          });
      } else {
        this.loadData();
      }
    },
    filteredData() {
      this.gemarkungenIsLoaded = false;
      this.splitGemarkung = false;
      this.$store.commit('ticket/SET_MEV_GEMARKUNGEN_DATA', []);
    },
    splitGemarkung(val) {
      if (this.getMevGemarkungenData.length === 0 && val) {
        this.$store
          .dispatch('ticket/GET_MEV_GEMARKUNGEN_DATA', {
            gemeinde: this.filteredData[0].gemeinde_id,
            richtlinie: this.richtlinie,
          })
          .then(() => {
            this.gemarkungenIsLoaded = true;
          });
      }
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    RL_2024_1() {
      return RL_2024_1;
    },
    RL_2024() {
      return RL_2024;
    },
    RL_2023() {
      return RL_2023;
    },
    RL_2022() {
      return RL_2022;
    },
    aggregateData(inputData, field, legendDataMapping) {
      const aggregatedData = {};

      inputData.forEach((item) => {
        item[field].forEach((subItem) => {
          const key = subItem[field];
          if (aggregatedData[key] !== undefined) {
            aggregatedData[key] += subItem.count;
          } else {
            aggregatedData[key] = subItem.count;
          }
        });
      });

      return Object.entries(aggregatedData).map(([key, value]) => ({
        name: legendDataMapping[Number(key)],
        value: value,
      }));
    },

    loadData() {
      this.$store
        .dispatch('ticket/GET_MEV_DASHBOARD_DATA', {
          richtlinie: this.richtlinie,
        })
        .then(() => {
          this.isLoaded = true;
          this.filteredData = this.uniqueNames.map((e) => e.gemeinde_id);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/components/storybook/src/assets/styles/index.scss';

.progress-date-item {
  @extend .standard-elevation-0;
  @apply bg-white rounded-[8px] overflow-hidden p-3 flex flex-col gap-3 w-fit h-fit text-blue-grey-700 items-center;
}
</style>
