import polygonBorderLayer from './polygonBorderLayer';

export const LAYER_KEY__VIEWING_LEVEL_GEMARKUNG = 'gemarkung';
export const LAYER_KEY__VIEWING_LEVEL_GEMEINDE = 'gemeinde';
export const LAYER_KEY__VIEWING_LEVEL_LANDKREIS = 'landkreis';

export const LAYER_KEY__VIEWING_LEVEL_GEMARKUNG_BORDER = 'gemarkungBorder';
export const LAYER_KEY__VIEWING_LEVEL_GEMEINDE_BORDER = 'gemeindeBorder';
export const LAYER_KEY__VIEWING_LEVEL_LANDKREIS_BORDER = 'landkreisBorder';

const viewingLevelConfig = (
  layerKey,
  name,
  router,
  subtitles,
  visibility = 'none'
) => ({
  layoutState: 'default',
  visible: visibility === 'visible',
  layerConfig: {
    app_name: 'ViewLevel',
    router_name: router,
    key_name: 'pk',
    type: 'polygon',
    id: layerKey,
    source: layerKey,
    sourceLayer: layerKey,
    visibility,
  },
  style: {
    default: {
      fillColor: 'rgba(96,189,255,0)',
      fillOpacity: 0,
    },
  },
  sidebar: {
    name,
    subtitles,
    chips: [
      {
        title: 'MEV',
        active: true,
        items: [
          {
            mevHeader: true,
            title: 'Versorgungsgrad',
            active: true,
            items: [
              {
                title: 'Weiße Flecken',
                key: 'count_coverage_wf',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Hell Graue Flecken',
                key: 'count_coverage_hgf',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Dunkel Graue Flecken',
                key: 'count_coverage_dgf',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Schwarze Flecken Kabel',
                key: 'count_coverage_sfk',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Schwarze Flecken FTTH',
                key: 'count_coverage_sff',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Sonderförderfähige Adressen',
                key: 'count_coverage_sfa',
                unit: '',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Homes Passed',
            active: false,
            items: [
              {
                title: 'Homes Passed FTTH',
                key: 'count_homespassed_ftth',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Homes Passed HFC',
                key: 'count_homespassed_hfc',
                unit: '',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Eigenausbau TKU',
            active: false,
            items: [
              {
                title: 'FTTH',
                key: 'count_ea_ftth',
                unit: '',
                decimal: 0,
              },
              {
                title: 'DSL',
                key: 'count_ea_hfc',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Supervectoring',
                key: 'count_ea_sv',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Abgelehnt',
                key: 'count_ea_sonstige',
                unit: '',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Standorte',
            active: false,
            items: [
              {
                title: 'Krankenhäuser',
                key: 'count_location_hospitel',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Schulen',
                key: 'count_location_school',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Unternehmen',
                key: 'count_location_company',
                unit: '',
                decimal: 0,
              },
              {
                title: 'SOES',
                key: 'count_location_soes',
                unit: '',
                decimal: 0,
              },
              {
                title: 'Schwer erschließb. Einzellage',
                key: 'count_location_see',
                unit: '',
                decimal: 0,
              },
            ],
          },
        ],
      },
      // {
      //   title: 'Förderantrag',
      //   active: false,
      //   items: [
      //     {
      //       title: 'Finanzierung',
      //       active: true,
      //       items: [
      //         {
      //           key: 'subsidies_subsidies',
      //           unit: '€',
      //           decimal: 0,
      //         },
      //         {
      //           key: 'subsidies_own_capital',
      //           unit: '€',
      //           decimal: 0,
      //         },
      //         {
      //           key: 'subsidies_capex',
      //           unit: '€',
      //           decimal: 0,
      //         },
      //         {
      //           key: 'subsidies_subsidies_quota',
      //           unit: '%',
      //           decimal: 1,
      //           multiply: 100,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        title: 'Netz',
        active: false,
        items: [
          {
            netHeader: true,
            title: 'Mengengerüste',
            active: true,
            items: [
              {
                key: 'underground_construction_portion',
                unit: 'm',
                decimal: 0,
              },
              {
                key: 'no_fiber_active',
                unit: 'Stk.',
                decimal: 0,
              },
              {
                key: 'length_micro',
                unit: 'm',
                decimal: 0,
              },
              {
                key: 'length_mini',
                unit: 'm',
                decimal: 0,
              },
              {
                key: 'length_pipes_house_connection',
                unit: 'm',
                decimal: 0,
              },
              {
                key: 'length_pipes_connection_nvt',
                unit: 'm',
                decimal: 0,
              },
              {
                key: 'length_pipes_nvt_pop',
                unit: 'm',
                decimal: 0,
              },
              {
                key: 'count_distributor',
                unit: 'Stk.',
                decimal: 0,
              },
              {
                key: 'count_pop',
                unit: 'Stk.',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Investitionen',
            active: false,
            items: [
              {
                key: 'capex_pipe',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'capex_cable',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'capex_network_distributor',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'capex_point_of_presence',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'capex_underground_construction',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'capex_connection_point_house_connection',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'capex_ancillary_costs',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              // {
              //   key: 'capex_risk',
              //   unit: 'Tsd. €',
              //   decimal: 0,
              //   multiply: 0.001,
              // },
            ],
          },
          {
            title: 'Erlöse und Rendite',
            active: false,
            items: [
              {
                key: 'revenues_residential_units',
                unit: ' €/a',
                decimal: 0,
              },
              {
                key: 'revenues_commercial_units',
                unit: ' €/a',
                decimal: 0,
              },
              {
                key: 'ebit',
                unit: ' €/a',
                decimal: 0,
              },
              {
                key: 'invest_yield',
                unit: ' %',
                decimal: 1,
                multiply: 100,
              },
            ],
          },
          {
            title: 'Finanzierung',
            active: false,
            items: [
              {
                key: 'capex',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'own_capital',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'subsidies',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'subsidies_quota',
                unit: '%',
                decimal: 2,
                multiply: 100,
              },
            ],
          },
        ],
      },
    ],
  },
});

export default {
  [LAYER_KEY__VIEWING_LEVEL_GEMARKUNG]: viewingLevelConfig(
    LAYER_KEY__VIEWING_LEVEL_GEMARKUNG,
    'Gemarkung',
    'Gemarkung',
    [
      { key: 'gemarkung' },
      { key: 'name' },
      { key: 'gemeinde' },
      { key: 'gemeinde_name' },
      { key: 'landkreis_name' },
    ]
  ),
  [LAYER_KEY__VIEWING_LEVEL_GEMARKUNG_BORDER]: polygonBorderLayer(
    LAYER_KEY__VIEWING_LEVEL_GEMARKUNG_BORDER,
    LAYER_KEY__VIEWING_LEVEL_GEMARKUNG
  ),
  [LAYER_KEY__VIEWING_LEVEL_GEMEINDE]: viewingLevelConfig(
    LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
    'Gemeinde',
    'Gemeinde',
    [{ key: 'ags' }, { key: 'name' }, { key: 'landkreis' }],
    'visible'
  ),
  [LAYER_KEY__VIEWING_LEVEL_GEMEINDE_BORDER]: polygonBorderLayer(
    LAYER_KEY__VIEWING_LEVEL_GEMEINDE_BORDER,
    LAYER_KEY__VIEWING_LEVEL_GEMEINDE,
    'visible'
  ),
  [LAYER_KEY__VIEWING_LEVEL_LANDKREIS]: viewingLevelConfig(
    LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
    'Landkreis',
    'Landkreis',
    [{ key: 'kreis_id' }, { key: 'name' }]
  ),
  [LAYER_KEY__VIEWING_LEVEL_LANDKREIS_BORDER]: polygonBorderLayer(
    LAYER_KEY__VIEWING_LEVEL_LANDKREIS_BORDER,
    LAYER_KEY__VIEWING_LEVEL_LANDKREIS
  ),
};
