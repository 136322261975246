<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="map-container">
    <data-panel
      v-if="getShowDataPanel"
      class="absolute top-[85px] flex items-stretch z-20 h-fit"
      :class="
        showPanel2 || (showSearch && getResultsLength !== 0)
          ? 'left-[360px]'
          : 'left-5'
      "
      v-bind="$attrs"
      @clicked="showSidebar(false)" />

    <div id="map" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  createMap,
  destroyMap,
  setStyle,
  setVisibility,
  setFilter,
  toggleSatellite,
} from '@/mapbox/main';
import config from '../../configs';
import { flyTo } from '@/mapbox/main';

export default {
  props: {
    showPanel2: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      layer: config.layer,
    };
  },
  computed: {
    ...mapGetters('map', ['getLayerStates', 'getShowDataPanel']),
    ...mapGetters('search', ['showSearch', 'getResults']),
    ...mapState('map', [
      'FlyTo',
      'satelliteIsActiv',
      'layerConfigs',
      'filters',
      'additionalFilters',
    ]),
    getResultsLength() {
      if (this.getResults !== null) return this.getResults.length;
      return 0;
    },
  },

  watch: {
    satelliteIsActiv(val) {
      toggleSatellite(val);
    },
    getLayerStates: {
      handler(val) {
        setVisibility(val);
        setStyle(val, this.layer, this);
      },
      deep: true,
    },
    filters: {
      handler(val) {
        Object.keys(val).forEach((e) => {
          let filter = val[e];
          if (
            e in this.additionalFilters &&
            this.additionalFilters[e].length > 0
          ) {
            if (filter === null) {
              filter = this.additionalFilters[e];
            } else {
              filter = ['all', filter, this.additionalFilters[e]];
            }
          }
          setFilter(e, filter);
        });
      },
      deep: true,
    },
    additionalFilters: {
      handler(val) {
        Object.keys(val).forEach((e) => {
          let filter = val[e];
          if (
            e in this.filters &&
            this.filters[e] !== null &&
            this.filters[e].length > 0
          ) {
            filter = ['all', filter, this.filters[e]];
          }
          setFilter(e, filter);
        });
      },
      deep: true,
    },
    FlyTo: {
      handler(val) {
        flyTo(val);
      },
      deep: true,
    },
  },
  mounted() {
    if ('layerName' in this.$route.params && 'lat' in this.$route.params && 'lng' in this.$route.params) {
      const { layerName, lat, lng } = this.$route.params;
      if (layerName && lat && lng) {
        const latNum = parseFloat(lat);
        const lngNum = parseFloat(lng);

        createMap(this, layerName, latNum, lngNum);
      } else {
        createMap(this);
      }
    }
    else if ('ags' in this.$route.params && 'mev_rl' in this.$route.params) {
      const { ags, mev_rl } = this.$route.params;
      if (ags && mev_rl) {
        createMap(this, null, null, null, ags, mev_rl)
      }
      else {
        createMap(this)
      }
    }
    else {
      createMap(this)
    }
  },
  unmounted() {
    destroyMap();
  },
  methods: {
    showSidebar(boolean) {
      this.$store.commit('map/SET_SHOW_DATA_PANEL', boolean);
    },
  },
};
</script>

<style lang="scss">
#map {
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.map-container {
  position: relative;
}

.mapboxgl-map {
  font: 12px/20px Helvetica Neue, Arial, Helvetica, sans-serif;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mapboxgl-canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
}

.mapboxgl-map:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

.mapboxgl-canary {
  background-color: salmon;
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-track-pointer {
  cursor: pointer;
}

.mapboxgl-canvas-container.mapboxgl-interactive:active,
.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate .mapboxgl-canvas {
  touch-action: pan-x pan-y;
}

.mapboxgl-canvas-container.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-drag-pan .mapboxgl-canvas {
  touch-action: pinch-zoom;
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan
  .mapboxgl-canvas {
  touch-action: none;
}
</style>
