<template>
  <button-el
    text="CSV Download"
    :primary="false"
    :full-width="true"
    :icon-right="true"
    :disabled="richtlinie !== 2023"
    icon="arrow_downward"
    @click="showDownloadDialog" />
  <v-dialog v-model="downloadDialogVisible" width="auto">
    <div class="flex flex-col gap-5 bg-white p-5 rounded-md">
      <div>
        <div>
          <p>
            Daten werden pro Gemeinde bereitgestellt und beinhalten folgendes:
          </p>
          <v-list lines="one">
            <v-list-item
              key="1"
              title="&bull; Finale Analyse und Grundlage für die Balkendiagramme" />
            <v-list-item key="2" title="&bull; GIS-Daten der finalen Analyse" />
            <v-list-item key="3" title="&bull; Rohdaten als Upload" />
          </v-list>
        </div>
      </div>
      <template v-if="!isLoading">
        <p v-if="infoText" class="text-infra-highlight-700">
          {{ infoText }}
        </p>
        <p v-else>
          Bitte wählen Sie eine Gemeinde aus und starten Sie den Download
          mittels Klick auf den Button
        </p>
        <div class="flex flex-row gap-5">
          <div class="flex-grow-1">
            <DropDown
              v-model="selectedGemeinde"
              :items-data="availableGemeindeForDownload"
              items-data-key="name"
              :disabled="Boolean(infoText)"
              :style="infoText ? { pointerEvents: 'none' } : {}"
              value="ags" />
          </div>
          <div class="flex-grow-0">
            <button-el
              :primary="true"
              text="Herunterladen"
              :disabled="!selectedGemeinde || Boolean(infoText)"
              @click="startDownload" />
          </div>
        </div>
        <div v-if="!isLoading" class="flex flex-row gap-5">
          <the-label
            label="Hinweis: In der Liste befinden sich lediglich Gemeinden, für die MEV-Daten verfügbar sind und auf denen Sie Zugriff haben" />
        </div>
      </template>
      <div v-else class="flex justify-center items-center">
        <v-progress-circular color="#001257" size="50" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    richtlinie: {
      type: [String, Number],
      default: 2022
    }
  },
  data() {
    return {
      downloadDialogVisible: false,
      selectedGemeinde: null,
      availableGemeindeForDownload: [],
      isLoading: false,
      infoText: '',
    };
  },
  methods: {
    showDownloadDialog() {
      this.selectedGemeinde = null;
      this.downloadDialogVisible = true;
      this.isLoading = true;
      this.$store
        .dispatch('map/GET_DOWNLOADABLE_GEMEINDEN')
        .then((resp) => {
          if (resp.gemeinden.length === 0) {
            this.infoText =
              'Für Ihr Gebiet stehen keine MEV-Daten zur Verfügung.';
          }
          this.availableGemeindeForDownload = resp.gemeinden;
        })
        .catch(() => {
          this.infoText =
            'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    startDownload() {
      this.$store
        .dispatch('map/DOWNLOAD_MEV_FILE', this.selectedGemeinde)
        .then(() => {
          this.downloadDialogVisible = false;
        });
    },
  },
};
</script>
